import type { FC } from "@sd/helpers/Typescript";
import { createContext, useContext, useState } from "react";

type AppContextValues = {
  title: string;
  setTitle: (t: string) => void;
};

export const AppContext = createContext({} as AppContextValues);

const AppProvider: FC = ({ children }) => {
  const [title, setTile] = useState("");

  const value: AppContextValues = {
    title,
    setTitle: (t) => setTile(t),
  };
  const ctxProps = { children, value };
  return <AppContext.Provider {...ctxProps} />;
};
export const useAppContext = () => useContext(AppContext);

export default AppProvider;
