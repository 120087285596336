import type { KeyboardEvent, MouseEvent } from "react";
import Box from "@mui/material/Box";
import LibDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NotificationIcon from "@mui/icons-material/Notifications";
import type { FC } from "@sd/helpers/Typescript";
import { Link } from "react-router-dom";

type DrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

type DrawerItemProps = {
  icon: FC;
  title: string;
  link: string;
};

const DrawerItem: FC<DrawerItemProps> = ({ icon: Icon, title, link }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton component={(props) => <Link {...props} to={link} />}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

const Drawer: FC<DrawerProps> = ({ open, setOpen }) => {
  const toggleDrawer = (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <LibDrawer anchor="left" open={open} onClose={toggleDrawer}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <List>
          <DrawerItem icon={DashboardIcon} title="Dashboard" link="/" />
          <DrawerItem
            icon={NotificationIcon}
            title="Notifications"
            link="/notification"
          />
        </List>
        {/* <Divider />
        <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Box>
    </LibDrawer>
  );
};

export default Drawer;
