import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import background from "@sd/assets/background.jpg";
import { ReactComponent as AppLogo } from "@sd/assets/logo.svg";
import styled from "styled-components";

export const Logo = styled(AppLogo)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const Main = styled(Grid).attrs({
  container: true,
  component: "main",
  sx: { height: "100vh" },
})``;

export const Background = styled(Grid).attrs({
  item: true,
  xs: false,
  sm: 4,
  md: 7,
  sx: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: ({ palette }) =>
      palette.mode === "light" ? palette.grey[50] : palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
})``;

export const Container = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 8,
  md: 5,
  component: Paper,
  elevation: 6,
  square: true,
})``;

export const Content = styled(Box).attrs({
  sx: {
    my: 8,
    mx: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
})``;

export const Title = styled(Typography).attrs({
  component: "h1",
  variant: "h5",
})``;

export const Form = styled(Box).attrs({
  component: "form",
  noValidate: true,
  sx: { mt: 1 },
})``;

export const SubmitButton = styled(Button).attrs({
  type: "submit",
  fullWidth: true,
  variant: "contained",
  sx: { mt: 3, mb: 2 },
})``;
