type CacheResponse = {
  [key: string]: any,
};

type WaitResponse = {
  [key: string]: Promise<any>,
};

type ResolveResponse = {
  [key: string]: (value: any) => void,
};

let cachedResponse: CacheResponse = {};
let waitCacheResponse: WaitResponse = {};
let resolveCacheResponse: ResolveResponse = {};

function useCache<T>(enabled: boolean) {
  function store(key: string, res: T) {
    if (!enabled) {
      cachedResponse[key] = undefined;
      return;
    }

    cachedResponse[key] = res;

    if (key in waitCacheResponse) {
      resolveCacheResponse[key](res);
      delete waitCacheResponse[key];
      delete resolveCacheResponse[key];
    }
  }

  async function get(key: string) {
    if (!enabled) {
      return;
    }

    if (key in waitCacheResponse) {
      return waitCacheResponse[key];
    }

    const hasCache = cachedResponse[key];
    if (!hasCache) {
      return;
    }

    return hasCache;
  }

  function clear() {
    cachedResponse = {};
    resolveCacheResponse = {};
    waitCacheResponse = {};
  }

  function wait(key: string) {
    if (!enabled) {
      return;
    }

    waitCacheResponse[key] = new Promise((resolve) => {
      resolveCacheResponse[key] = resolve;
    });
  }

  return {
    store,
    get,
    clear,
    wait,
  };
}

export default useCache;
