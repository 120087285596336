import LoadingButton from "@mui/lab/LoadingButton";
import styled from "styled-components";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";

export const SubmitButton = styled(LoadingButton).attrs({
  type: "submit",
  variant: "contained",
  sx: { mt: 3, mb: 2 },
  loadingPosition: "start",
  startIcon: <SendIcon />,
})({
  marginRight: 16,
});

export const DraftButton = styled(LoadingButton).attrs({
  type: "button",
  sx: { mt: 3, mb: 2 },
  loadingPosition: "start",
  startIcon: <SaveIcon />,
})``;

export const DeleteButton = styled(LoadingButton).attrs({
  type: "button",
  sx: { mt: 3, mb: 2 },
  loadingPosition: "start",
  startIcon: <DeleteIcon />,
  variant: "outlined",
  color: "error",
})``;
