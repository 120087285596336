import { NOTIFICATION_API_URL } from "@sd/constants/EnvConfig";
import useAuthFetch from "@sd/hooks/useAuthFetch";

type DraftNotificationResponse = {
  batchId: string;
};

type Recipient = {
  userId: string;
};

type DraftNotificationData = {
  batchId?: string;
  topic?: string;
  recipients?: Recipient[];
  title: string;
  message: string;
  body: string;
  scheduledAt?: string;
  channel?: "app";
};

type DraftNotification = (
  data: DraftNotificationData
) => Promise<DraftNotificationResponse>;

type UseDraftNotificationApi = [boolean, DraftNotification];

function useDraftNotificationApi(): UseDraftNotificationApi {
  const { loading, post, put } = useAuthFetch<
    DraftNotificationResponse,
    DraftNotificationData
  >({});

  const draft: DraftNotification = (data) => {
    const { batchId, ...form } = data;
    const body = {
      ...form,
      channel: "app" as const,
    };

    if (batchId) {
      return put({
        url: `${NOTIFICATION_API_URL}/admin/batch/${batchId}`,
        data: body,
      });
    }

    return post({
      url: `${NOTIFICATION_API_URL}/admin/batch`,
      data: body,
    });
  };
  return [loading, draft];
}

export default useDraftNotificationApi;
