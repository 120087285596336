import { useAuthContext } from "@sd/context/AuthContext";
import useAnyApi from "@sd/hooks/api/useAnyApi";
import { useState } from "react";

export type InvokeLogin = (username: string, password: string) => void;

type UseLoginApi = [boolean, InvokeLogin, string];

function useLoginApi(): UseLoginApi {
  const { signIn } = useAuthContext();
  const [loading, invokeApi] = useAnyApi();
  const [error, setError] = useState("");

  function invokeSignIn(username: string, password: string) {
    invokeApi(async () => {
      try {
        setError("");
        const email = `${username}`.toLowerCase();
        const creds = { email, password };
        await signIn(creds);
      } catch (ex: any) {
        const message = (ex && ex.message) || ex || "Invalid User Credential";
        setError(message);
      }
    });
  }

  return [loading, invokeSignIn, error];
}

export default useLoginApi;
