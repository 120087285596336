import {
  BrowserRouter,
  Route,
  Routes as LibRoutes,
  Navigate,
} from "react-router-dom";
import LogIn from "@sd/Login";
import Dashboard from "@sd/Dashboard";
import { useAuthContext } from "@sd/context/AuthContext";
import SharedLayout from "@sd/components/SharedLayout";
import Loader from "@sd/components/Loader";
import type { FC } from "@sd/helpers/Typescript";
import AddNotification from "@sd/AddNotification";
import Notification from "@sd/Notification";
import NotificationDetail from "@sd/NotificationDetail";

const Routes: FC = () => {
  const { loading } = useAuthContext();

  if (loading) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

const AppRoutes: FC = () => {
  const { isLogged } = useAuthContext();

  if (!isLogged) {
    return (
      <>
        <LibRoutes>
          <Route path="/" element={<LogIn />} />
          <Route path="*" element={<Navigate to="/" />} />
        </LibRoutes>
      </>
    );
  }

  return (
    <SharedLayout>
      <LibRoutes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/notification/:id" element={<NotificationDetail />} />
        <Route path="/notification/add" element={<AddNotification />} />
      </LibRoutes>
    </SharedLayout>
  );
};

export default Routes;
