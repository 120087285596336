import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { DraftButton, SubmitButton } from "@sd/AddNotification/style";
import Autocomplete from "@sd/components/Autocomplete";
import Datepicker from "@sd/components/Datepicker";
import RadioButtons from "@sd/components/RadioButtons";
import Select, { SelectItem } from "@sd/components/Select";
import TextEditor from "@sd/components/TextEditor";
import TextField from "@sd/components/TextField";
import { useAppContext } from "@sd/context/AppContext";
import { REQUIRED_RULE } from "@sd/helpers/Form";
import useDraftNotificationApi from "@sd/hooks/api/useDraftNotification";
import useSubmitNotificationApi from "@sd/hooks/api/useSubmitNotification";
import useTopicsApi from "@sd/hooks/api/useTopicsApi";
import useUsersApi from "@sd/hooks/api/useUsersApi";
import useForm from "@sd/hooks/useForm";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useRef } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import moment from "moment";

type Schedule = "NOW" | "LATER";

type Topic = "NEW" | string;

type SubmitData = {
  topic: Topic;
  userIds: SelectItem[];
  title: string;
  message: string;
  body: string;
  schedule: Schedule;
  scheduledAt: Date;
};

const NEW_TOPIC = [
  {
    text: "Send to specific users",
    value: "NEW",
  },
];

const SCHEDULE_AT = [
  {
    text: "Send Now",
    value: "NOW",
  },
  {
    text: "Send Later",
    value: "LATER",
  },
];

function AddNotification() {
  const { setTitle } = useAppContext();
  const [userLoading, allUsers, listUser] = useUsersApi();
  const [topicLoading, allTopics] = useTopicsApi();
  const [draftLoading, draftNotification] = useDraftNotificationApi();
  const [submitLoading, submitNotification] = useSubmitNotificationApi();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const batchId = useRef("");

  const users = useMemo<SelectItem[]>(
    () =>
      allUsers.map(({ email, id }) => ({
        text: email,
        value: id,
      })),
    [allUsers]
  );

  const topicList = useMemo<SelectItem[]>(() => {
    if (topicLoading) {
      return [{ text: "Loading ...", value: "" }];
    }

    const formatted = allTopics.map(({ topic }) => ({
      text: topic,
      value: topic,
    }));
    return [...formatted, ...NEW_TOPIC];
  }, [topicLoading, allTopics]);

  const form = useForm<SubmitData>();
  const { handleSubmit, reset, watch } = form;

  const [topic, schedule] = watch(["topic", "schedule"]);

  async function onSubmit(data: SubmitData) {
    if (!batchId.current) {
      const id = await onDraft(data, true);
      if (!id) {
        return;
      }
    }

    await submitNotification({ batchId: batchId.current });

    batchId.current = "";
    const { value } = topicList?.[0];
    reset({ topic: value, schedule: "NOW" });
    enqueueSnackbar("Notification Sent", { variant: "success" });
    navigate("/notification");
  }

  async function onDraft(data: SubmitData, preSubmit?: boolean) {
    try {
      const { topic, userIds, title, message, body, schedule, scheduledAt } =
        data;

      const isNew = topic === "NEW";
      const { batchId: id } = await draftNotification({
        topic: !isNew ? topic : undefined,
        recipients: isNew
          ? userIds.map(({ value }) => ({ userId: value }))
          : undefined,
        title,
        message,
        body,
        scheduledAt:
          schedule === "LATER" ? moment(scheduledAt).toJSON() : undefined,
      });

      batchId.current = id;
      if (!preSubmit) {
        enqueueSnackbar("Notification Draft Saved", { variant: "success" });
        return;
      }

      return id;
    } catch (ex) {
      const { message } = ex as any;
      enqueueSnackbar(message, { variant: "error" });
    }
  }

  useEffect(() => {
    setTitle("Notification");
  }, [setTitle]);

  useEffect(() => {
    if (!topic || topic !== "NEW") {
      return;
    }
    listUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  useEffect(() => {
    if (!topicList) {
      return;
    }

    const { value } = topicList?.[0];
    reset({ topic: value, schedule: "NOW" });
  }, [reset, topicList]);

  return (
    <Container>
      <FormProvider {...form}>
        <Card component="form" onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Select
              name="topic"
              label="Category"
              options={topicList}
              rules={{
                ...REQUIRED_RULE,
              }}
            />
            {topic === "NEW" && (
              <Autocomplete
                name="userIds"
                label="Users"
                placeholder="Emails"
                options={users}
                loading={userLoading}
                limitTags={2}
              />
            )}
            <TextField
              name="title"
              label="Subject"
              rules={{
                ...REQUIRED_RULE,
              }}
            />
            <TextField
              name="body"
              label="Short Description"
              rules={{
                ...REQUIRED_RULE,
              }}
            />
            <TextEditor
              name="message"
              label="Message"
              rules={{
                ...REQUIRED_RULE,
              }}
            />
            <RadioButtons
              name="schedule"
              label="Send At"
              options={SCHEDULE_AT}
            />
            {schedule === "LATER" && (
              <Datepicker name="scheduledAt" label="Send Later" />
            )}
            <SubmitButton loading={draftLoading || submitLoading}>
              Send Notification
            </SubmitButton>
            <DraftButton
              loading={draftLoading}
              onClick={handleSubmit((data) => onDraft(data))}
            >
              Save as draft
            </DraftButton>
          </CardContent>
        </Card>
      </FormProvider>
    </Container>
  );
}

export default AddNotification;
