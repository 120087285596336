import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import LibSelect, {
  SelectChangeEvent,
  SelectProps as LibSelectProps,
} from "@mui/material/Select";
import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import type { FormInputProps } from "@sd/helpers/Form";

export type SelectItem = {
  text: string;
  value: string;
};

type PureSelectProps = {
  label: string;
  onChange: (val: string) => void;
  value: string;
  message?: string;
  error?: boolean;
  options: SelectItem[];
} & Omit<LibSelectProps, "onChange" | "value">;

type SelectProps = Omit<PureSelectProps, "onChange" | "value"> & FormInputProps;

// const AppSelect = styled(LibSelect)<LibSelectProps<string>>(({ error }) => ({
//   "input, textarea": {
//     color: error ? red[400] : "",
//   },
// }));

const Select: FC<SelectProps> = ({ name, rules, defaultValue, ...props }) => {
  const { control } = useFormContext();

  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const inputProps = {
    ...props,
    inputRef,
    onChange,
    value,
    message,
    error,
  };

  return <PureSelect {...inputProps} />;
};

export const PureSelect: FC<PureSelectProps> = ({
  label,
  onChange,
  value,
  error,
  message,
  options,
  ...rest
}) => {
  const onSelectChange = (e: SelectChangeEvent) => {
    onChange(e.target.value);
  };

  const props = {
    ...rest,
    label,
    // TODO: LATER
    onChange: onSelectChange,
    value: `${value || ""}`,
    error,
    variant: "outlined" as const,
    fullWidth: true,
    defaultValue: "",
  };

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel>{label}</InputLabel>
      <LibSelect {...props}>
        {options.map(({ text, value }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </LibSelect>
      {message && <FormHelperText error={error}>{message}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
