import Copyright from "@sd/components/Copyright";
import Loader from "@sd/components/Loader";
import TextField from "@sd/components/TextField";
import { REQUIRED_RULE } from "@sd/helpers/Form";
import { FC } from "@sd/helpers/Typescript";
import useLoginApi, { InvokeLogin } from "@sd/hooks/api/useLoginApi";
import useForm from "@sd/hooks/useForm";
import {
  Background,
  Container,
  Content,
  Form,
  Logo,
  Main,
  SubmitButton,
  Title,
} from "@sd/Login/style";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";

type SubmitData = {
  username: string;
  password: string;
};

type LoginFormProps = {
  signIn: InvokeLogin;
};

const LoginForm: FC<LoginFormProps> = ({ signIn }) => {
  const form = useForm<SubmitData>();
  const { handleSubmit } = form;

  async function onSubmit(data: SubmitData) {
    const { username, password } = data;
    signIn(username, password);
  }

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Username"
          name="username"
          rules={{
            ...REQUIRED_RULE,
          }}
        />
        <TextField
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          rules={{
            ...REQUIRED_RULE,
          }}
        />
        <SubmitButton>Sign In</SubmitButton>
        <Copyright />
      </Form>
    </FormProvider>
  );
};

function LogIn() {
  const [loading, signIn, error] = useLoginApi();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!error) {
      return;
    }

    enqueueSnackbar(error, { variant: "error" });
  }, [error, enqueueSnackbar]);

  return (
    <Main>
      <Background />
      <Container>
        <Content>
          <Logo />
          <Title>Sign in</Title>
          {loading ? <Loader height={500} /> : <LoginForm signIn={signIn} />}
        </Content>
      </Container>
    </Main>
  );
}

export default LogIn;
