import { useCallback, useState } from "react";
import useAuthFetch from "@sd/hooks/useAuthFetch";
import { API_URL } from "@sd/constants/EnvConfig";

type UseLoginApi = [boolean, User[], () => Promise<void>];

type User = {
  id: string;
  enabled: boolean;
  userStatus: string; // enum
  createdAt: Date;
  updatedAt: Date;
  memberType: number;
  emailVerified: boolean;
  phoneVerified: boolean;
  phone: string;
  firstName: string;
  title: number;
  lastName: string;
  email: string;
  accountStatus?: number; // enum
  lmsUserId: string;
};

type GetAdminUsers = {
  users: User[];
  paginationToken: string;
};

type RefreshUser = (
  params?: Partial<GetAdminUsers>
) => Promise<Pick<GetAdminUsers, "users">>;

function useUsersApi(): UseLoginApi {
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const { get } = useAuthFetch<GetAdminUsers>({});
  const [loading, setLoading] = useState(false);

  const refresh: RefreshUser = useCallback(
    async (params) => {
      const { paginationToken = "", users = [] } = params ?? {};
      const searchParams = new URLSearchParams({ paginationToken });
      const { paginationToken: token, users: updated } = await get({
        url: `${API_URL}/admin/users?${searchParams}`,
      });

      const allUsers = [...users, ...updated];

      if (!token) {
        setLoading(false);
        return { users: allUsers };
      }

      return refresh({ paginationToken: token, users: allUsers });
    },
    [get]
  );

  const listAll = async () => {
    setLoading(true);
    const { users } = await refresh();
    setAllUsers(users);
  };

  return [loading, allUsers, listAll];
}

export default useUsersApi;
