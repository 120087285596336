import type { FormInputProps } from "@sd/helpers/Form";
import type { FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import styled from "styled-components";

type PureTextEditorProps = {
  label: string;
  message?: string;
  error?: boolean;
} & ReactQuillProps;

type TextEditorProps = Omit<PureTextEditorProps, "onChange" | "value"> &
  FormInputProps;

const Gap = styled.div({ height: 10 });

const AppTextEditor = styled(ReactQuill)({
  ".ql-toolbar": { borderRadius: "5px 5px 0px 0px" },
  ".ql-container": { height: 250, borderRadius: "0px 0px 5px 5px" },
});

const TextEditor: FC<TextEditorProps> = ({
  name,
  rules,
  defaultValue,
  ...props
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, value },
    fieldState: { error: { message } = {} },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const inputProps = {
    ...props,
    onChange,
    value,
    message,
    error: !!message,
    formats: [
      "bold",
      "italic",
      "link",
      "size",
      "strike",
      "underline",
      "blockquote",
      "header",
      "indent",
      "list",
      "align",
      "direction",
    ],
  };

  return <PureTextEditor {...inputProps} />;
};

export const PureTextEditor: FC<PureTextEditorProps> = ({
  label,
  message,
  error,
  ...props
}) => (
  <>
    <FormControl fullWidth margin="normal">
      <FormLabel>{label}</FormLabel>
      <Gap />
      <AppTextEditor {...props} />
      {message && <FormHelperText error={error}>{message}</FormHelperText>}
    </FormControl>
  </>
);

export default TextEditor;
