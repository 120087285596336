import { useCallback, useState, useEffect, useRef } from "react";
import useAuthFetch from "@sd/hooks/useAuthFetch";
import { NOTIFICATION_API_URL } from "@sd/constants/EnvConfig";

type Status =
  | "draft"
  | "pending"
  | "processing"
  | "cancelled"
  | "completed"
  | "failed";

type StatusItem = {
  text: string;
  value: Status;
};

export const STATUS_LIST: StatusItem[] = [
  {
    text: "Draft",
    value: "draft",
  },
  {
    text: "Pending",
    value: "pending",
  },
  {
    text: "Processing",
    value: "processing",
  },
  {
    text: "Completed",
    value: "completed",
  },
  {
    text: "Cancelled",
    value: "cancelled",
  },
  {
    text: "Failed",
    value: "failed",
  },
];

export const STATUS: Record<Status, string> = STATUS_LIST.reduce(
  (prev, { text, value }) => ({
    ...prev,
    [value]: text,
  }),
  {} as Record<Status, string>
);

export type AttributesData = {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
};

export type Recipient = {
  userId: string;
  token: string;
  email: string;
  attributesData: AttributesData;
};

export type Data = {
  appIcon: string;
  appDeepLink: string;
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
};

export type BatchNotification = {
  batchId: string;
  channel: "app";
  status: Status;
  recipients: Recipient[];
  topic: string;
  title: string;
  body: string;
  contentId: string;
  message: string;
  data: Data;
  notificationId: string;
  scheduledAt: Date;
  senderName: string;
  sender: string;
  createdBy: string;
  lastUpdatedBy: string;
  submittedBy: string;
  submittedAt: Date;
  processedAt: Date;
  completedAt: Date;
  createdDate: string;
  createdAt: Date;
  updatedAt: Date;
};

export type BatchNotificationLite = Pick<
  BatchNotification,
  | "batchId"
  | "channel"
  | "status"
  | "topic"
  | "title"
  | "scheduledAt"
  | "submittedAt"
  | "processedAt"
  | "completedAt"
  | "createdDate"
  | "createdAt"
  | "updatedAt"
>;

export type GetAdminNotifications = {
  batchNotifications: BatchNotificationLite[];
  nextToken: string;
};

type UseNotificationsApi = (
  status: Status
) => [boolean, BatchNotificationLite[], () => Promise<void>];

type Refresh = (
  params: Partial<GetAdminNotifications> & { status: Status }
) => Promise<Pick<GetAdminNotifications, "batchNotifications">>;

const useNotificationsApi: UseNotificationsApi = (status) => {
  const [allNotifications, setAllNotifications] = useState<
    BatchNotificationLite[]
  >([]);
  const [loading, setLoading] = useState(false);
  const { get } = useAuthFetch<GetAdminNotifications>({});
  const nextTokenRef = useRef<string>();

  const refresh: Refresh = useCallback(
    async (params) => {
      const { nextToken = "", batchNotifications = [], status } = params ?? {};
      const searchParams = new URLSearchParams({
        nextToken,
        status,
        limit: "100",
        channel: "app",
      });
      const { nextToken: token, batchNotifications: updated } = await get({
        url: `${NOTIFICATION_API_URL}/admin/batch/summary?${searchParams}`,
      });

      const allNotifications = [...batchNotifications, ...updated];

      if (!token || nextTokenRef.current === token) {
        setLoading(false);
        return { batchNotifications: allNotifications };
      }

      nextTokenRef.current = token;

      return refresh({
        nextToken: token,
        batchNotifications: allNotifications,
        status,
      });
    },
    [get]
  );

  const listAll = async () => {
    setLoading(true);
    const { batchNotifications } = await refresh({ status });
    setAllNotifications(batchNotifications);
  };

  useEffect(() => {
    listAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [loading, allNotifications, listAll];
};

export default useNotificationsApi;
