import LibChip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import { useEffect, useState } from "react";
import { Container } from "@sd/components/FilterChips/style";
import type { FC } from "@sd/helpers/Typescript";

type ChipProps = {
  isSelect: boolean;
  label: string;
  onClick: () => void;
};

type ChipItem<Value> = {
  text: string;
  value: Value;
};

type FilterChipsProps<Value> = {
  selected: Value[];
  onChange: (item: Value[]) => void;
  items: ChipItem<Value>[];
};

const Chip: FC<ChipProps> = ({ isSelect, label, onClick }) => {
  const icon = isSelect ? <DoneIcon fontSize="small" /> : undefined;
  const color = isSelect ? "secondary" : undefined;
  return <LibChip icon={icon} color={color} label={label} onClick={onClick} />;
};

const FilterChips = <Value extends string>({
  selected: prev,
  onChange,
  items = [],
}: FilterChipsProps<Value>) => {
  const [selected, setSelected] = useState<Value[]>(prev ?? []);

  useEffect(() => {
    onChange(selected);
  }, [onChange, selected]);

  function updateSelected(item: Value) {
    const found = selected.includes(item);
    if (!found) {
      setSelected((s) => {
        const all = [...s, item];
        if (all.length === items.length) {
          return [];
        }

        return all;
      });
    } else {
      setSelected((s) => s.filter((i) => i !== item));
    }
  }

  return (
    <Container>
      <Chip
        isSelect={selected.length === 0 || selected.length === items.length}
        label="All"
        onClick={() => setSelected([])}
      />
      {items.map(({ text, value }) => (
        <Chip
          key={value}
          isSelect={selected.includes(value)}
          label={text}
          onClick={() => updateSelected(value)}
        />
      ))}
    </Container>
  );
};

export default FilterChips;
