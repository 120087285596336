import { useAuthContext } from "@sd/context/AuthContext";
import { UnauthorizedError } from "@sd/helpers/AppError";
import useFetch from "@sd/hooks/useFetch";
import { useCallback, useEffect, useMemo } from "react";

type UseAuthFetch = typeof useFetch;

const useAuthFetch: UseAuthFetch = (options) => {
  const { userToken, isLogged, signOut } = useAuthContext();
  const authOps = useMemo(
    () => ({
      token: userToken,
    }),
    [userToken]
  );

  const { get, post, put, del, reset, ...rest } = useFetch({
    ...options,
    ...authOps,
    url: userToken ? options.url : "",
  });
  const { error } = rest ?? {};

  type MakeFetch = typeof get;

  const makeGet: MakeFetch = useCallback(
    (ops) => get({ ...ops, ...authOps }),
    [get, authOps]
  );

  const makePost: MakeFetch = useCallback(
    (ops) => post({ ...ops, ...authOps }),
    [post, authOps]
  );
  const makePut: MakeFetch = useCallback(
    (ops) => put({ ...ops, ...authOps }),
    [put, authOps]
  );
  const makeDelete: MakeFetch = useCallback(
    (ops) => del({ ...ops, ...authOps }),
    [del, authOps]
  );

  useEffect(() => {
    if (isLogged) {
      return;
    }

    reset();
  }, [isLogged, reset]);

  useEffect(() => {
    if (!error) {
      return;
    }

    if (error instanceof UnauthorizedError) {
      signOut();
    }
  }, [error, signOut]);

  return {
    get: makeGet,
    post: makePost,
    put: makePut,
    del: makeDelete,

    reset,
    ...rest,
  };
};

export default useAuthFetch;
