import { NOTIFICATION_API_URL } from "@sd/constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

type SubmitNotificationRequest = {
  batchId: string;
};

type SubmitNotification = (
  data: SubmitNotificationRequest
) => Promise<SubmitNotificationRequest>;

type UseSubmitNotificationApi = [boolean, SubmitNotification];

function useSubmitNotificationApi(): UseSubmitNotificationApi {
  const { loading, post } = useAuthFetch<
    SubmitNotificationRequest,
    SubmitNotificationRequest
  >({});

  const submit: SubmitNotification = (data) => {
    return post({
      url: `${NOTIFICATION_API_URL}/admin/batch/submit`,
      data,
    });
  };
  return [loading, submit];
}

export default useSubmitNotificationApi;
