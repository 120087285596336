import Typography from "@mui/material/Typography";
import { useAppContext } from "@sd/context/AppContext";
import { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "85vh",
});

function Home() {
  const { setTitle } = useAppContext();

  useEffect(() => {
    setTitle("Dashboard");
  }, [setTitle]);

  return (
    <Container>
      <Typography variant="h3">Dashboard</Typography>
    </Container>
  );
}

export default Home;
