import { NOTIFICATION_API_URL } from "@sd/constants/EnvConfig";
import useAuthFetch from "@sd/hooks/useAuthFetch";

type DeleteNotificationData = {
  batchId: string;
};

type DeleteNotification = (data: DeleteNotificationData) => Promise<void>;

type UseDeleteNotificationApi = [boolean, DeleteNotification];

function useDeleteNotificationApi(): UseDeleteNotificationApi {
  const { loading, del } = useAuthFetch<void, DeleteNotificationData>({});

  const deleted: DeleteNotification = (data) => {
    const { batchId } = data;
    return del({
      url: `${NOTIFICATION_API_URL}/admin/batch/${batchId}`,
    });
  };
  return [loading, deleted];
}

export default useDeleteNotificationApi;
