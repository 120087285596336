import Button from "@mui/material/Button";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

export const AddButton = styled(Button).attrs({
  variant: "contained",
  sx: { mt: 3, mb: 2 },
  startIcon: <AddIcon />,
  component: Link,
  to: "/notification/add",
})``;
