import Container from "@mui/material/Container";
import FilterChips from "@sd/components/FilterChips";
import Loader from "@sd/components/Loader";
import Table, { HeadCell } from "@sd/components/Table";
import { useAppContext } from "@sd/context/AppContext";
import { formatStandard } from "@sd/helpers/DateTime";
import useNotificationsApi, {
  BatchNotificationLite,
  STATUS,
  STATUS_LIST,
} from "@sd/hooks/api/useNotifications";
import { useEffect, useMemo, useState } from "react";
import { AddButton } from "@sd/Notification/style";

const HEADER_CELLS: HeadCell<BatchNotificationLite>[] = [
  {
    id: "title",
    numeric: false,
    label: "Title",
    addLink: ({ batchId }) => `/notification/${batchId}`,
  },
  {
    id: "status",
    numeric: false,
    label: "Status",
    format: ({ status }) => STATUS[status],
  },
  {
    id: "createdAt",
    numeric: true,
    label: "Created At",
    format: ({ createdAt }) => formatStandard(createdAt),
  },
];

function Notification() {
  const { setTitle } = useAppContext();
  const [draftLoading, allDraft] = useNotificationsApi("draft");
  const [pendingLoading, allPending] = useNotificationsApi("pending");
  const [processingLoading, allProcessing] = useNotificationsApi("processing");
  const [cancelledLoading, allCancelled] = useNotificationsApi("cancelled");
  const [completedLoading, allCompleted] = useNotificationsApi("completed");
  const [failedLoading, allfailed] = useNotificationsApi("failed");

  const [selected, setSelected] = useState<BatchNotificationLite["status"][]>(
    []
  );

  const filtered = useMemo<BatchNotificationLite[]>(() => {
    let updated = [] as BatchNotificationLite[];
    if (!selected || selected.length === 0) {
      return [
        ...allDraft,
        ...allPending,
        ...allProcessing,
        ...allCompleted,
        ...allCancelled,
        ...allfailed,
      ];
    }

    if (selected.includes("draft")) {
      updated = [...updated, ...allDraft];
    }

    if (selected.includes("pending")) {
      updated = [...updated, ...allPending];
    }

    if (selected.includes("processing")) {
      updated = [...updated, ...allProcessing];
    }

    if (selected.includes("completed")) {
      updated = [...updated, ...allCompleted];
    }

    if (selected.includes("cancelled")) {
      updated = [...updated, ...allCancelled];
    }

    if (selected.includes("failed")) {
      updated = [...updated, ...allfailed];
    }

    return updated;
  }, [
    selected,
    allDraft,
    allPending,
    allProcessing,
    allCancelled,
    allCompleted,
    allfailed,
  ]);

  const loading =
    draftLoading ||
    pendingLoading ||
    processingLoading ||
    cancelledLoading ||
    completedLoading ||
    failedLoading;

  useEffect(() => {
    setTitle("Notification");
  }, [setTitle]);

  return (
    <Container>
      <FilterChips
        selected={selected}
        onChange={(selected) => setSelected(selected)}
        items={STATUS_LIST}
      />
      <AddButton>Add New Notification</AddButton>
      {loading ? (
        <Loader />
      ) : (
        <Table
          headCells={HEADER_CELLS}
          items={filtered}
          title="Notification"
          orderBy="createdAt"
        />
      )}
    </Container>
  );
}

export default Notification;
