import { appHttp } from "@sd/helpers/Http";
import { isObject } from "@sd/helpers/Object";
import useCache from "@sd/hooks/useCache";
import { AxiosRequestConfig } from "axios";
import { useCallback, useEffect, useState } from "react";

type UseFetchOpt<TRes, TBody> = {
  url?: string;
  default?: TRes;
  cache?: boolean;
  focusEffect?: boolean;
} & AxiosRequestConfig<TBody>;

type MakeFetch<TRes, TBody> = (
  ops?: AxiosRequestConfig<TBody>
) => Promise<TRes>;

type UseFetchResponse<TRes, TBody> = {
  response?: TRes;
  error?: Error;
  loading: boolean;

  reset: () => void;
  get: MakeFetch<TRes, TBody>;
  post: MakeFetch<TRes, TBody>;
  put: MakeFetch<TRes, TBody>;
  del: MakeFetch<TRes, TBody>;
};

function useFetch<TRes, TBody = null>(
  options: UseFetchOpt<TRes, TBody>
): UseFetchResponse<TRes, TBody> {
  const [response, setResponse] = useState<TRes>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);

  const {
    url,
    default: defaultRes,
    cache = false,
    focusEffect = false,
    ...opts
  } = options;
  const { get: getCache, store: storeCache, wait: waitCache } = useCache(cache);

  const makeFetch: MakeFetch<TRes, TBody> = useCallback(
    async (ops) => {
      async function getOrCache() {
        const { url: updated, ...restOps } = ops ?? {};
        const apiUrl = updated ?? url;
        if (!apiUrl) {
          // setResponse();
          return response;
        }

        const cached = await getCache(apiUrl);
        if (cached) {
          return cached;
        }

        waitCache(apiUrl);

        const httpOptions = {
          ...opts,
          ...restOps,
        };

        // TODO: remove once publish
        console.log("appHttp", {
          apiUrl,
          method: httpOptions.method,
        });

        const result = await appHttp<TRes, TBody>(apiUrl, httpOptions);
        storeCache(apiUrl, result);
        return result;
      }

      setLoading(true);
      try {
        setError(undefined);
        const res = await getOrCache();
        setResponse((r) => (isObject(r) ? { ...r, ...res } : res));
        setTimeout(() => {
          setLoading(false);
        }, 300);
        return res;
      } catch (ex) {
        setError(ex as Error);
        setLoading(false);
        setResponse(defaultRes);

        if (defaultRes) {
          return defaultRes;
        }

        console.log({ ex });
        throw ex;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  useEffect(() => {
    if (focusEffect) {
      return;
    }

    makeFetch();
  }, [focusEffect, makeFetch]);

  const get = useCallback(
    (ops?: AxiosRequestConfig<TBody>) => makeFetch(ops),
    [makeFetch]
  );
  const post = useCallback(
    (ops?: AxiosRequestConfig<TBody>) => makeFetch({ ...ops, method: "POST" }),
    [makeFetch]
  );
  const put = useCallback(
    (ops?: AxiosRequestConfig<TBody>) => makeFetch({ ...ops, method: "PUT" }),
    [makeFetch]
  );
  const del = useCallback(
    (ops?: AxiosRequestConfig<TBody>) =>
      makeFetch({ ...ops, method: "DELETE" }),
    [makeFetch]
  );

  const reset = useCallback(() => {
    setResponse(undefined);
    setError(undefined);
    setLoading(false);
  }, []);

  return {
    response,
    error,
    loading,

    reset,

    get,
    post,
    put,
    del,
  };
}

export default useFetch;
