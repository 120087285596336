import { Title } from "@sd/components/Copyright/style";

function Copyright() {
  return (
    <Title>
      {"Copyright © "}
      Smart Dentist {" • "}
      {new Date().getFullYear()}
    </Title>
  );
}

export default Copyright;
