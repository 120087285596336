import { isEmpty } from "@sd/helpers/String";

export function isObject(obj: any) {
  return obj && typeof obj === "object" && obj.constructor === Object;
}

export const convertObjectList = (lookup = [], prefix = "") =>
  lookup.reduce((prev, { value, text }) => {
    const key = prefix ? `${prefix}-${value}` : value;
    return { ...prev, [key]: text };
  }, {});

export const isEmptyObject = (obj: any) =>
  !obj || Object.keys(obj).length === 0;

export const clean: any = (obj: any) =>
  Object.entries(obj)
    .filter(([_, v]) => !isEmpty(v))
    .reduce(
      (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? clean(v) : v }),
      {}
    );
