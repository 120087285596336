import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import {
  DeleteButton,
  DraftButton,
  SubmitButton,
} from "@sd/AddNotification/style";
import Autocomplete from "@sd/components/Autocomplete";
import Datepicker from "@sd/components/Datepicker";
import Loader from "@sd/components/Loader";
import RadioButtons from "@sd/components/RadioButtons";
import Select, { SelectItem } from "@sd/components/Select";
import TextEditor from "@sd/components/TextEditor";
import TextField from "@sd/components/TextField";
import { useAppContext } from "@sd/context/AppContext";
import { REQUIRED_RULE } from "@sd/helpers/Form";
import useDeleteNotificationApi from "@sd/hooks/api/useDeleteNotification";
import useDraftNotificationApi from "@sd/hooks/api/useDraftNotification";
import useNotificationApi from "@sd/hooks/api/useNotification";
import useSubmitNotificationApi from "@sd/hooks/api/useSubmitNotification";
import useTopicsApi from "@sd/hooks/api/useTopicsApi";
import useUsersApi from "@sd/hooks/api/useUsersApi";
import useForm from "@sd/hooks/useForm";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useRef } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

type Schedule = "NOW" | "LATER";

type Topic = "NEW" | string;

type SubmitData = {
  topic: Topic;
  userIds: SelectItem[];
  title: string;
  message: string;
  body: string;
  schedule: Schedule;
  scheduledAt: Date;
};

const NEW_TOPIC = [
  {
    text: "Send to specific users",
    value: "NEW",
  },
];

const SCHEDULE_AT = [
  {
    text: "Send Now",
    value: "NOW",
  },
  {
    text: "Send Later",
    value: "LATER",
  },
];

function NotificationDetail() {
  const { setTitle } = useAppContext();
  const { id } = useParams();
  const [userLoading, allUsers, listUser] = useUsersApi();
  const [topicLoading, allTopics] = useTopicsApi();
  const [draftLoading, draftNotification] = useDraftNotificationApi();
  const [deleteLoading, deleteNotification] = useDeleteNotificationApi();
  const [submitLoading, submitNotification] = useSubmitNotificationApi();
  const [loading, notification] = useNotificationApi(`${id}`);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { status } = notification ?? {};

  const isDraft = status === "draft";
  const isSubmitted = !isDraft;

  const batchId = useRef("");

  const users = useMemo<SelectItem[]>(
    () =>
      allUsers.map(({ email, id }) => ({
        text: email,
        value: id,
      })),
    [allUsers]
  );

  const topicList = useMemo<SelectItem[]>(() => {
    if (topicLoading) {
      return [{ text: "Loading ...", value: "" }];
    }

    const formatted = allTopics.map(({ topic }) => ({
      text: topic,
      value: topic,
    }));
    return [...formatted, ...NEW_TOPIC];
  }, [topicLoading, allTopics]);

  const form = useForm<SubmitData>();
  const { handleSubmit, reset, watch } = form;

  const [topic, schedule] = watch(["topic", "schedule"]);

  async function onSubmit(data: SubmitData) {
    if (!batchId.current) {
      const id = await onDraft(data, true);
      if (!id) {
        return;
      }
    }

    await submitNotification({ batchId: batchId.current });

    batchId.current = "";
    const { value } = topicList?.[0];
    reset({ topic: value, schedule: "NOW" });
    enqueueSnackbar("Notification Sent", { variant: "success" });
    navigate("/notification");
  }

  async function onDraft(data: SubmitData, preSubmit?: boolean) {
    try {
      const { topic, userIds, title, message, body, schedule, scheduledAt } =
        data;

      const isNew = topic === "NEW";
      const { batchId: updatedId } = await draftNotification({
        batchId: id,
        topic: !isNew ? topic : undefined,
        recipients: isNew
          ? userIds.map(({ value }) => ({ userId: value }))
          : undefined,
        title,
        message,
        body,
        scheduledAt:
          schedule === "LATER" ? moment(scheduledAt).toJSON() : undefined,
      });

      batchId.current = updatedId;
      if (!preSubmit) {
        enqueueSnackbar("Notification Draft Saved", { variant: "success" });
        return;
      }

      return updatedId;
    } catch (ex) {
      const { message } = ex as any;
      enqueueSnackbar(message, { variant: "error" });
    }
  }

  async function onDelete(data: SubmitData) {
    try {
      await deleteNotification({
        batchId: `${id}`,
      });

      enqueueSnackbar("Notification Draft Removed", { variant: "success" });
      navigate("/notification");
    } catch (ex) {
      const { message } = ex as any;
      enqueueSnackbar(message, { variant: "error" });
    }
  }

  useEffect(() => {
    setTitle("Notification");
  }, [setTitle]);

  useEffect(() => {
    if (!notification || !users) {
      return;
    }

    const { topic, recipients, title, message, body, scheduledAt } =
      notification;

    const userObj = users.reduce(
      (prev, { text, value }) => ({
        ...prev,
        [value]: text,
      }),
      {} as Record<string, string>
    );

    reset({
      topic: recipients && recipients.length !== 0 ? "NEW" : topic,
      userIds: recipients?.map(({ userId }) => ({
        value: userId,
        text: userObj?.[userId] || userId,
      })),
      title,
      message,
      body,
      schedule: scheduledAt ? "LATER" : "NOW",
      scheduledAt,
    });
  }, [reset, notification, users]);

  useEffect(() => {
    if (!topic || topic !== "NEW") {
      return;
    }
    listUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <FormProvider {...form}>
        <Card component="form" onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Select
              name="topic"
              label="Category"
              options={topicList}
              rules={{
                ...REQUIRED_RULE,
              }}
              readOnly={isSubmitted}
            />
            {topic === "NEW" && (
              <Autocomplete
                name="userIds"
                label="Users"
                placeholder="Emails"
                options={users}
                loading={userLoading}
                limitTags={2}
                readOnly={isSubmitted}
              />
            )}
            <TextField
              name="title"
              label="Subject"
              rules={{
                ...REQUIRED_RULE,
              }}
              readOnly={isSubmitted}
            />
            <TextField
              name="body"
              label="Short Description"
              rules={{
                ...REQUIRED_RULE,
              }}
              readOnly={isSubmitted}
            />
            <TextEditor
              name="message"
              label="Message"
              rules={{
                ...REQUIRED_RULE,
              }}
              readOnly={isSubmitted}
            />
            <RadioButtons
              name="schedule"
              label="Send At"
              options={SCHEDULE_AT}
              disabled={isSubmitted}
            />
            {schedule === "LATER" && (
              <Datepicker name="scheduledAt" label="Send Later" />
            )}
            {isDraft && (
              <SubmitButton loading={draftLoading || submitLoading}>
                Send Notification
              </SubmitButton>
            )}
            {isDraft && (
              <DraftButton
                loading={draftLoading}
                onClick={handleSubmit((data) => onDraft(data))}
              >
                Save as draft
              </DraftButton>
            )}
            {isDraft && (
              <DeleteButton
                loading={deleteLoading}
                onClick={handleSubmit((data) => onDelete(data))}
              >
                Delete draft
              </DeleteButton>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </Container>
  );
}

export default NotificationDetail;
