import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";

import { ChangeEvent, FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import type { FormInputProps } from "@sd/helpers/Form";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";

export type RadioItem = {
  text: string;
  value: string;
};

type PureSelectProps = {
  label: string;
  onChange: (val: string) => void;
  value: string;
  message?: string;
  error?: boolean;
  options: RadioItem[];
  disabled?: boolean;
} & Omit<RadioGroupProps, "onChange" | "value">;

type SelectProps = Omit<PureSelectProps, "onChange" | "value"> & FormInputProps;

const RadioButtons: FC<SelectProps> = ({
  name,
  rules,
  defaultValue,
  ...props
}) => {
  const { control } = useFormContext();

  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const inputProps = {
    ...props,
    inputRef,
    onChange,
    value,
    message,
    error,
    name,
  };

  return <PureRadioButtons {...inputProps} />;
};

export const PureRadioButtons: FC<PureSelectProps> = ({
  label,
  onChange,
  value,
  error,
  message,
  options,
  name,
  disabled,
  ...rest
}) => {
  const onRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <FormControl fullWidth margin="normal">
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        defaultValue="female"
        name={name}
        onChange={onRadioChange}
        value={`${value || ""}`}
      >
        {options.map(({ text, value }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={text}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {message && <FormHelperText error={error}>{message}</FormHelperText>}
    </FormControl>
  );
};

export default RadioButtons;
