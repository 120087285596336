import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { FC } from "@sd/helpers/Typescript";
import useWindowSize from "@sd/hooks/useWindowSize";
import styled from "styled-components";

type AppLoaderProps = {
  full?: boolean;
};

type LoaderProps = {
  paper?: boolean;
  height?: number;
} & AppLoaderProps;

type ContainerProps = {
  $height?: number;
};

const Container = styled.div<ContainerProps>(({ $height = 250 }) => ({
  minHeight: $height,
  minWidth: 250,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const AppLoader: FC<ContainerProps> = ({ $height }) => (
  <Container $height={$height}>
    <CircularProgress />
  </Container>
);

const Loader: FC<LoaderProps> = ({ paper, full, height: overwriteHeight }) => {
  const { height } = useWindowSize(overwriteHeight ? false : full, 200);

  return paper ? (
    <Paper>
      <AppLoader $height={overwriteHeight ?? height} />
    </Paper>
  ) : (
    <AppLoader $height={overwriteHeight ?? height} />
  );
};

export default Loader;
