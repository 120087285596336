import { useState } from "react";

type Function = () => Promise<void>;
type Callback = (cb: Function) => Promise<void>;

type UseAnyApi = [boolean, Callback, () => void];

function useAnyApi(oriFn?: Function): UseAnyApi {
  const [loading, setLoading] = useState(false);

  async function invokeApi(fn = oriFn) {
    setLoading(true);
    try {
      const response = fn ? await fn() : await Promise.resolve();
      setLoading(false);
      return response;
    } catch (ex) {
      setLoading(false);
      throw ex;
    }
  }

  function clearLoading() {
    setLoading(false);
  }

  return [loading, invokeApi, clearLoading];
}

export default useAnyApi;
