import LibAutocomplete, {
  AutocompleteProps as LibAutocompleteProps,
} from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import red from "@mui/material/colors/red";
import LibTextField, {
  TextFieldProps as LibTextFieldProps,
} from "@mui/material/TextField";
import { styled } from "@mui/system";
import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import type { FormInputProps } from "@sd/helpers/Form";

type SelectItem = {
  text: string;
  value: string;
};

type PureAutocompleteProps = {
  label: string;
  onChange: (val: SelectItem[]) => void;
  value: SelectItem[];
  message?: string;
  error?: boolean;
  options: SelectItem[];
} & Omit<
  LibAutocompleteProps<SelectItem, true, true, false>,
  "renderInput" | "options"
>;

type AutocompleteProps = Omit<PureAutocompleteProps, "onChange" | "value"> &
  FormInputProps;

const TextField = styled(LibTextField)<LibTextFieldProps>(({ error }) => ({
  "input, textarea": {
    color: error ? red[400] : "",
  },
}));

const Autocomplete: FC<AutocompleteProps> = ({
  name,
  rules,
  defaultValue,
  ...props
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, value },
    fieldState: { error: { message } = {} },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const inputProps = {
    ...props,
    onChange,
    value: value || [],
    message,
    error: !!message,
  };
  return <PureAutocomplete {...inputProps} />;
};

export const PureAutocomplete: FC<PureAutocompleteProps> = ({
  label,
  placeholder,
  onChange,
  message,
  error,
  loading,
  options,
  ...props
}) => (
  <LibAutocomplete
    {...props}
    multiple
    options={options}
    loading={loading}
    onInputChange={(event, inputValue) => {
      const updatedValues = inputValue
        .split(";")
        .map((s) => `${s || ""}`.trim())
        .filter((s) => !!s);

      if (updatedValues.length === 0) {
        return;
      }

      const filtered = options.filter(({ text }) =>
        updatedValues.some((e) => e === text)
      );

      if (filtered.length === 0) {
        return;
      }
      onChange(filtered);
    }}
    onChange={(event, newValue) => {
      const filtered = options.filter(({ value }) =>
        newValue.some((e) => e.value === value)
      );
      onChange(filtered);
    }}
    renderTags={(tagValue, getTagProps) => {
      return tagValue.map(({ text }, index) => (
        <Chip label={text} {...getTagProps({ index })} />
      ));
    }}
    getOptionLabel={(option) => option.text}
    isOptionEqualToValue={(option: SelectItem, value: SelectItem) =>
      option.value === value.value
    }
    renderInput={(params) => (
      <TextField
        {...params}
        margin="normal"
        label={label}
        placeholder={placeholder}
        error={error}
        helperText={message}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
  />
);

export default Autocomplete;
