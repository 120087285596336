import { useEffect, useState } from "react";

type UseWindowSizeHook = {
  width: number;
  height: number;
};

type UseWindowSize = (
  enabled?: boolean,
  offsetHeight?: number
) => UseWindowSizeHook;

const useWindowSize: UseWindowSize = (enabled = true, offsetHeight = 0) => {
  const [windowSize, setWindowSize] = useState<UseWindowSizeHook>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!enabled) {
      return;
    }

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight - offsetHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [enabled, offsetHeight]);

  return windowSize;
};

export default useWindowSize;
