import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginBottom: theme.spacing(1),
  "& > *": {
    margin: theme.spacing(0.5),
  },
}));
