import LibContainer from "@mui/material/Container";
import Drawer from "@sd/components/Drawer";
import Navbar from "@sd/components/Navbar";
import { useAppContext } from "@sd/context/AppContext";
import useWindowSize from "@sd/hooks/useWindowSize";
import { useState } from "react";
import styled from "styled-components";

import type { FC } from "@sd/helpers/Typescript";

type MainProps = {
  $height: number;
};

const Root = styled.div({});

const AppBarFlex = styled.div(({ theme }) => theme.mixins.toolbar);

const Main = styled.main<MainProps>(({ $height }) => ({
  overflow: "auto",
  height: $height,
}));

const Container = styled(LibContainer).attrs({
  component: "main",
})(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(10),
  paddingLeft: 0,
  paddingRight: 0,
  flex: 1,
}));

const SharedLayout: FC = ({ children }) => {
  const [open, setOpen] = useState(false);

  const { height } = useWindowSize();
  const { title } = useAppContext();

  const drawerProps = {
    open,
    setOpen,
  };

  return (
    <Root>
      <Navbar {...drawerProps} open={title} />
      <Drawer {...drawerProps} />
      <Main $height={height}>
        <AppBarFlex />
        <Container>{children}</Container>
      </Main>
    </Root>
  );
};

export default SharedLayout;
