import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import LibAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import LibToolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useAuthContext } from "@sd/context/AuthContext";
import type { FC } from "@sd/helpers/Typescript";
import styled from "styled-components";

type NavbarProps = {
  open: string;
  setOpen: (open: boolean) => void;
};

const Toolbar = styled(LibToolbar)({
  paddingRight: 24,
});

const MenuButton = styled(IconButton).attrs({
  edge: "start",
  color: "inherit",
})({});

const MainTitle = styled(Typography).attrs({
  component: "h1",
  variant: "h6",
  color: "inherit",
  noWrap: true,
})({
  flexGrow: 1,
});

const AppBar = styled(LibAppBar)({
  transition: ".3s",
});

const Navbar: FC<NavbarProps> = ({ open: title, setOpen }) => {
  const { signOut } = useAuthContext();

  // const history = useHistory();
  // const { pathname } = useLocation();
  // const hasHistory = useMemo(() => pathname.split("/").length > 4, [pathname]);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleBack = () => {
  //   try {
  //     if (history.length <= 1) {
  //       throw new Error("No Route");
  //     }

  //     history.goBack();
  //   } catch (ex) {
  //     history.replace("/");
  //   }
  // };

  return (
    <AppBar position="absolute">
      <Toolbar>
        <MenuButton onClick={() => setOpen(true)}>
          <MenuIcon />
        </MenuButton>

        {/* {history.length <= 1 && (
          <MenuButton onClick={handleBack}>
            <ArrowBackIcon />
          </MenuButton>
        )} */}

        <MainTitle>{title}</MainTitle>
        <IconButton color="inherit" onClick={signOut}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
