import { NOTIFICATION_API_URL } from "@sd/constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

type UseTopicsApi = [boolean, Topic[]];

type Topic = {
  topic: string;
  status: number;
  createdDate: Date;
};

function useTopicsApi(): UseTopicsApi {
  const { loading, response = [] } = useAuthFetch<Topic[]>({
    url: `${NOTIFICATION_API_URL}/admin/topics/app`,
  });
  return [loading, response];
}

export default useTopicsApi;
