import { Auth } from "@aws-amplify/auth";
import {
  AUTH_IDENTITY_POOL_ID,
  AUTH_OAUTH_URL,
  AUTH_REDIRECT_URL,
  AUTH_USER_POOL_ID,
  AUTH_USER_POOL_WEB_CLIENT_ID,
  AWS_REGION,
} from "@sd/constants/EnvConfig";
import AppProvider from "@sd/context/AppContext";
import AuthProvider from "@sd/context/AuthContext";
import ThemeProvider from "@sd/context/ThemeContext";
import Routes from "@sd/Routes";
import { SnackbarProvider } from "notistack";

Auth.configure({
  Auth: {
    identityPoolId: AUTH_IDENTITY_POOL_ID,
    region: AWS_REGION,
    identityPoolRegion: AWS_REGION,
    userPoolId: AUTH_USER_POOL_ID,
    userPoolWebClientId: AUTH_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: AUTH_OAUTH_URL,
      scope: ["openid", "profile"],
      redirectSignIn: AUTH_REDIRECT_URL,
      redirectSignOut: AUTH_REDIRECT_URL,
      responseType: "code",
    },
  },
});

function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <AppProvider>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </AppProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
