import { Auth } from "@aws-amplify/auth";
import { CurrentUser, getAccessToken, getCurrentUser } from "@sd/helpers/Auth";
import { FC } from "@sd/helpers/Typescript";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";

export const AuthContext = createContext({} as AuthContextValues);

type AuthType = "RESTORE_TOKEN" | "SIGN_IN" | "SIGN_OUT";

type AuthReducerAction = {
  type: AuthType;
  token?: string;
  user?: CurrentUser;
};

type LoginOpt = {
  email: string;
  password: string;
};

type AuthReducerState = {
  isLogged: boolean;
  loading: boolean;
  userToken?: string;
  user?: CurrentUser;
};

type AuthContextValues = {
  signIn: (o: LoginOpt) => Promise<void>;
  signOut: () => Promise<void>;
} & AuthReducerState;

type AuthReducer = (
  prevState: AuthReducerState,
  action: AuthReducerAction
) => AuthReducerState;

const authReducer: AuthReducer = (prevState, { type, token, user }) => {
  switch (type) {
    case "RESTORE_TOKEN":
      return {
        ...prevState,
        isLogged: !!token,
        loading: false,
        userToken: token,
        user,
      };

    case "SIGN_IN":
      return {
        ...prevState,
        isLogged: true,
        loading: false,
        userToken: token,
        user,
      };
    case "SIGN_OUT":
      return {
        ...prevState,
        isLogged: false,
        loading: false,
        userToken: undefined,
        user: undefined,
      };
    default:
      throw new Error(`Unhandled type: ${type}`);
  }
};

const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    isLogged: false,
    loading: true,
  });

  const getUserDetails = useCallback(async (bypassCache = false) => {
    try {
      const [token] = await Promise.all([getAccessToken(bypassCache)]);
      const user = await getCurrentUser();
      return { token, user };
    } catch (ex) {
      return;
    }
  }, []);

  useEffect(() => {
    const initToken = async () => {
      const user = await getUserDetails();
      dispatch({ type: "RESTORE_TOKEN", ...user });
    };

    initToken();
  }, [getUserDetails]);

  async function signIn({ email, password }: LoginOpt) {
    await Auth.signIn(email, password);
    const detail = await getUserDetails(true);
    if (!detail) {
      return;
    }

    const { token, user } = detail;

    dispatch({ type: "SIGN_IN", token, user });
  }

  async function signOut() {
    await Auth.signOut();
    dispatch({ type: "SIGN_OUT" });
  }

  const value: AuthContextValues = {
    ...state,
    signIn,
    signOut,
  };
  const ctxProps = { children, value };
  return <AuthContext.Provider {...ctxProps} />;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
