import { useCallback } from "react";
import useAuthFetch from "@sd/hooks/useAuthFetch";
import { NOTIFICATION_API_URL } from "@sd/constants/EnvConfig";
import { BatchNotification } from "@sd/hooks/api/useNotifications";

type UseNotificationsApi = (
  id: string
) => [
  boolean,
  BatchNotification | undefined,
  (id: string) => Promise<BatchNotification>
];

type Refresh = (id: string) => Promise<BatchNotification>;

const useNotificationApi: UseNotificationsApi = (id) => {
  const { loading, response, get } = useAuthFetch<BatchNotification>({
    url: `${NOTIFICATION_API_URL}/admin/batch/${id}`,
  });

  const refresh: Refresh = useCallback(
    async (id) =>
      await get({
        url: id ? `${NOTIFICATION_API_URL}/admin/batch/${id}` : undefined,
      }),
    [get]
  );

  return [loading, response, refresh];
};

export default useNotificationApi;
