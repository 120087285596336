import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { UnauthorizedError } from "@sd/helpers/AppError";

type HttpOptions<TBody> = {
  token?: string;
} & AxiosRequestConfig<TBody>;

type HttpError = AxiosError<{
  message: string;
}>;

export async function appHttp<TRes, TBody>(
  url: string,
  options: HttpOptions<TBody>
): Promise<TRes | null> {
  const { headers, token, ...rest } = options ?? {};

  const defaultOptions = {
    url,
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
    ...rest,
  };

  try {
    const response = await axios.request<TRes>(defaultOptions);
    const { data } = response ?? {};

    if (response.status === 204) {
      return null;
    }

    return data;
  } catch (error) {
    const { message: errMessage, response } = error as HttpError;
    const message = response?.data?.message || errMessage;

    if (response?.status === 401) {
      throw new UnauthorizedError(message);
    }

    throw new Error(message);
  }
}
