export const {
  REACT_APP_AWS_REGION: AWS_REGION,
  REACT_APP_AUTH_IDENTITY_POOL_ID: AUTH_IDENTITY_POOL_ID,
  REACT_APP_AUTH_USER_POOL_ID: AUTH_USER_POOL_ID,
  REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID: AUTH_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AUTH_OAUTH_URL: AUTH_OAUTH_URL,
  REACT_APP_AUTH_REDIRECT_URL: AUTH_REDIRECT_URL,
  REACT_APP_API_URL: API_URL,
  REACT_APP_QUESTION_API_URL: QUESTION_API_URL,
  REACT_APP_NOTIFICATION_API_URL: NOTIFICATION_API_URL,
  REACT_APP_IMAGE_URL: IMAGE_URL,
} = process.env;
