import red from "@mui/material/colors/red";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import LibTextField, {
  TextFieldProps as LibTextFieldProps,
} from "@mui/material/TextField";
import { styled } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  DateTimePicker,
  DateTimePickerProps as LibDateTimePickerProps,
} from "@mui/x-date-pickers/DateTimePicker";
import { Moment } from "moment";
import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import type { FormInputProps } from "@sd/helpers/Form";

type Label = {
  label: string;
};

type PureDateTimePickerProps = {
  onChange: (val: Moment | null) => void;
  value: Moment;
  message?: string;
  error?: boolean;
} & DateTimePickerProps;

type DateTimePickerProps = Label &
  Omit<
    LibDateTimePickerProps<Moment, Moment>,
    "renderInput" | "onChange" | "value"
  >;

const TextField = styled(LibTextField)<LibTextFieldProps>(({ error }) => ({
  "input, textarea": {
    color: error ? red[400] : "",
  },
}));

const Datepicker: FC<DateTimePickerProps & FormInputProps> = ({
  name,
  rules,
  defaultValue,
  ...props
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, value },
    fieldState: { error: { message } = {} },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <PureDatepicker
      {...props}
      onChange={onChange}
      value={value}
      message={message}
      error={!!message}
    />
  );
};

export const PureDatepicker: FC<PureDateTimePickerProps> = ({
  message,
  error,
  ...props
}) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <FormControl fullWidth>
      <DateTimePicker
        {...props}
        renderInput={(iProps) => <TextField {...iProps} margin="normal" />}
      />
      <FormHelperText error={error}>{message}</FormHelperText>
    </FormControl>
  </LocalizationProvider>
);

export default Datepicker;
