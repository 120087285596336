import { useForm as useLibForm } from "react-hook-form";

type USeFormHook = typeof useLibForm;

const useForm: USeFormHook = (props) => {
  return useLibForm({
    ...props,
    mode: "onSubmit",
  });
};

export default useForm;
